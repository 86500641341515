<template>
  <div>
    <div class="modal" tabindex="-1" role="dialog" id="ModalCaptcha">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Generar formato
            </h5>
          </div>
          <div class="modal-body">
            <div class="row mb-2" v-if="avance > 0">
              <div class="col-12 text-center">
                <div class="progress" style="height: 10px;">
                  <div class="progress-bar" role="progressbar" :style="avanceStyle" :aria-valuenow="avance" aria-valuemin="0"
                       aria-valuemax="100">
                  </div>
                </div>
                <small>{{ progresoTexto }}</small>
              </div>
            </div>
            <div class="row mb-2" v-if="error">
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  Error, el formato no pudo ser generado, revise su conexión e intente más tarde.
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="form-group">
                <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    :sitekey="key"
                    @expired="onExpired"
                >
                </vue-recaptcha>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" v-if="captcha && avance == 0" class="btn btn-primary" @click="generarFormato">Generar formato
            </button>
            <button type="button" class="btn btnSecondary" data-dismiss="modal">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapActions} from "vuex";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "ModalCaptcha",
  props: {
    titulo: String,
    nombreArchivo: String,
    url: String,
    tipoFormato: Number,
  },
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      captcha: false,
      avance: 0,
      progresoTexto: 'Guardando información...',
      error: false,
    };
  },
  computed: {
    ...mapState("generals", ["key"]),
    avanceStyle(){
      return {
        width: this.avance+'px'
      }
    }
  },
  methods: {
    ...mapActions('contenido', ['guardarFormato', 'generarPDF']),
    async generarFormato() {
      try {
        this.avance = 40;
        await this.guardarFormato({ nombreFormato: this.titulo, tipoFormato: this.tipoFormato});
        this.progresoTexto = 'Generando archivo...';
        this.avance = 70;
        await this.generarPDF({url: this.url, nombreFormato: this.nombreArchivo});
        window.$("#ModalCaptcha").modal("hide");
        this.$router.push({ name: "inicio" });
      } catch (e) {
        this.error = true;
        this.resetRecaptcha();
        this.captcha= false;
        console.log(e);
      } finally {
        this.avance = 100;
        this.avance = 0;
        this.resetRecaptcha();
        this.captcha= false;
      }
    },
    onVerify: function (response) {
      if (response) {
        this.captcha = true;
      }
    },
    onExpired: function () {
      console.log("Expired");
      this.captcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
  },
};
</script>
<style scoped>
.modal-body {
  display: block !important;
}

.btnSecondary {
  background: lightgray !important;
  color: black !important;
}
</style>