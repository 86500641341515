<template>
  <div style="margin: 10px">
    <div class="row">
      <div class="col-12 mb-3">
        <ul class="list-group">
          <li class="list-group-item active">
            Selecciona las pruebas con las que cuenta.
          </li>
          <li
            class="list-group-item"
            v-for="p in pruebas"
            :key="p.id"
          >
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                :id="`check${p.id}`"
                :value="p"
                v-model="pruebasSelected"
              />
              <label class="form-check-label" :for="`check${p.id}`">
                {{ p.texto }}
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import {auth} from "@/firebase";

export default {
  name: "Pruebas",
  data() {
    return {
      pruebasSelected: []
    };
  },
  mounted() {
    // Se valida el cambio de estatus y los datos, que se han capturado para poder fianlizar, o pasar al
    // siguiente paso.
    let self = this;
    auth.onAuthStateChanged( function (user) {
      if ( user && self.pruebasSelected.length > 0 ) {
        self.$emit("can-continue", { value: true });
      }
    });
  },
  activated: function () {
    console.log("activate");
    if (this.pruebasSelected.length > 0 ) {
      // console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  computed: {
    ...mapState("generals", ["pruebas"]),
  },
  methods: {
    ...mapMutations('contenido', ['setFormatoDatos'])
  },
  watch : {
    pruebasSelected: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid);
        if (val.length > 0) {
          this.setFormatoDatos({ pruebasSelected: this.pruebasSelected });
          this.$emit("can-continue", { value: true });
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true,
    },
  }
};
</script>
<style scoped>
.list-group-item.active {
  z-index: 2;
  color: #191919;
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.alert-secondary {
  color: #e2e3e5;
  background-color: #1c1c38;
  border-color: #1c1c38;
}
</style>